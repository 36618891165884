import hrn from "../images/hrn.jpg"
import myp from "../images/myp.jpg"
import aup from "../images/aup.jpg"

export default [
  {
    org: "Hi Right Now",
    orgLink: "https://www.hirightnow.co/",
    text:
      "Empowering communities to form meaningful connections, one 1:1 video conversation at a time.",
    header: "Build stronger  ties within your online community.",
    desc1:
      "To achieve that, Hi Right Now built a web-app that serendipitously connects inspiring thinkers, creators, and leaders of online communities over a series of 6-min 1:1 video chats via a matching algorithm.",
    desc2:
      "Hi Right Now is on a mission to empower humanity to form deep meaningful connections.",
    getInvolved:
      "https://launch.hirightnow.co/sign-up?planType=free&billingPeriod=forever",
    learnMore: "https://www.hirightnow.co/our-story.html",
    brandColor: "#6738aa",
    img: hrn,
  },
  {
    org: "Meet Your Pearl",
    orgLink: "https://meetyourpearl.com/",
    text:
      "As the world becomes more digital, people are less inclined to physically interact with one another.",
    header:
      "Pearls is on a mission to bring us back to our roots of meeting physically to learn, grow and show who we are as individuals.",
    desc1:
      "To accomplish this, Pearls is a social and dating application for those who love pearls, Boba and bubble tea. Pearls wants to bring people and the world together, one pearl at a time. Join us on this adventure and help us spread the love of Pearls across the globe.",
    desc2:
      "If you don't like pearls, Boba or bubble tea, then shame. But still apply nonetheless.",
    getInvolved: "https://meetyourpearl.com/jobs",
    learnMore: "https://meetyourpearl.com/about",
    brandColor: "#687eeb",
    img: myp,
  },
  {
    org: "AUP",
    orgLink: "https://www.facebook.com/aaspn/",
    text: "Let's work together.",
    header: "You can make a difference.",
    desc1:
      "COVID-19 is especially dangerous for older folks (50+) and immunocompromised individuals.",
    desc2:
      "Elderly Asian individuals are arguably the most affected by this. Not only do they face discrimination, they also have barriers of accessibility, mobility, and language.",
    getInvolved:
      "https://docs.google.com/forms/d/e/1FAIpQLSe4gKjPdmm3JEyRIQzafYfn7XPwSVnfcCo_ADmHOACEMq_zAQ/viewfo",
    learnMore: "https://aupcovid19.org/",
    brandColor: "#feb339",
    img: aup,
  },
]
