import * as pic from "../images/profile"
import React from "react"
import { FaLinkedinIn, FaLink, FaInstagram } from "react-icons/fa"

export default [
  {
    name: "Laura Bagk",
    title: "Community Manager",
    pronouns: "she/her",
    picture: pic.LauraBagk,
    socials: [
      {
        link: "https://ca.linkedin.com/in/laurabagk",
        icon: <FaLinkedinIn />,
      },
      {
        link: "https://www.instagram.com/laurabagk/",
        icon: <FaInstagram />
      }
    ],
  },
  {
    name: "Linda Nguyen",
    title: "Content Lead / Podcast",
    pronouns: "she/her",
    picture: pic.LindaNguyen,
    socials: [
      {
        link: "https://www.linkedin.com/in/keepnguyening",
        icon: <FaLinkedinIn />,
      },
    ],
  },
  // {
  //   name: "Jessica Cornel",
  //   title: "Design Lead",
  //   pronouns: "she/her",
  //   picture: pic.JessicaCornel,
  //   socials: [
  //     {
  //       link: "https://www.linkedin.com/in/jessica-cornel-18b237158/",
  //       icon: <FaLinkedinIn />,
  //     },
  //     { link: "https://www.instagram.com/jess_cornel/", icon: <FaInstagram /> },
  //   ],
  // },
  {
    name: "Jasmine Nauta",
    title: "Events Lead",
    pronouns: "she/her",
    picture: pic.JasmineNauta,
    socials: [
      {
        link: "https://www.linkedin.com/in/jasminenauta/",
        icon: <FaLinkedinIn />,
      }
    ],
  },
  {
    name: "Mizuho Miyagawa",
    title: "Marketing Lead",
    pronouns: "she/her",
    picture: pic.MizuhoMiyagawa,
    socials: [
      {
        link: "https://www.linkedin.com/in/mizuho-miyagawa/",
        icon: <FaLinkedinIn />,
      },
      { link: "https://www.instagram.com/mizuho.jm/", icon: <FaInstagram /> },
      {
        link: "https://www.instagram.com/mjm_creative/",
        icon: <FaLink />,
      },
    ],
  },

  {
    name: "Mark Catangui",
    title: "Moderator Lead",
    pronouns: "he/him",
    picture: pic.MarkCatangui,
    socials: [
      {
        link: "hhttps://www.instagram.com/morkcatangui/",
        icon: <FaInstagram />,
      },
      { link: "https://www.markcatangui.com/", icon: <FaLink /> },
    ],
  },

  // {
  //   name: "Dorothy Ng",
  //   title: "Podcast Lead",
  //   pronouns: "she/her",
  //   picture: pic.DorothyNg,
  //   socials: [
  //     {
  //       link: "https://www.linkedin.com/in/dorothyng604/",
  //       icon: <FaLinkedinIn />,
  //     },
  //     { link: "https://instagram.com/daiqon", icon: <FaInstagram /> },
  //   ],
  // },
  {
    name: "Eddy Leung",
    title: "Project Manager",
    pronouns: "he/him",
    picture: pic.EddyLeung,
    socials: [
      {
        link: "https://www.linkedin.com/in/eddy-leung899",
        icon: <FaLinkedinIn />,
      },
    ],
  },
  // {
  //   name: "Taylor Moy",
  //   title: "Social Media Lead",
  //   pronouns: "she/her",
  //   picture: pic.TaylorMoy,
  //   socials: [
  //     {
  //       link: "https://www.linkedin.com/in/taylormoy/",
  //       icon: <FaLinkedinIn />,
  //     },
  //     { link: "https://www.instagram.com/tmoy_media/", icon: <FaInstagram /> },
  //     { link: "https://taylormmoy.wordpress.com/", icon: <FaLink /> },
  //   ],
  // },
  {
    name: "Cathleen Sun",
    title: "Strategic Development Lead",
    pronouns: "she/her",
    picture: pic.CathleenSun,
    socials: [
      {
        link: "https://www.instagram.com/_cathleensun/",
        icon: <FaInstagram />,
      },
    ],
  },
  {
    name: "Danny Li",
    title: "Website Lead",
    pronouns: "he/him",
    picture: pic.DannyLi,
    socials: [
      {
        link: "https://www.linkedin.com/in/d-li/",
        icon: <FaLinkedinIn />,
      },
      {
        link: "https://dannyboy.dev/",
        icon: <FaLink />,
      },
    ],
  },
]
