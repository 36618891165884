import React, { Component } from "react"
import { Link } from "gatsby"

import Image from "../components/image"

const links = ["About", "Stories", "Partners", "Events", "Contact"]

export default class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isHidden: false,
    }

    this.hideNav = this.hideNav.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.hideNav)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideNav)
  }

  hideNav() {
    let { isHidden } = this.state

    window.scrollY > this.prev && document.documentElement.scrollTop > 100
      ? !isHidden && this.setState({ isHidden: true })
      : isHidden && this.setState({ isHidden: false })

    this.prev = window.scrollY
  }

  hamburger() {
    const nav = document.getElementById("nav")

    if (nav.className === "nav") {
      nav.className += " responsive"
    } else {
      nav.className = "nav"
    }
  }

  render() {
    const hide = this.state.isHidden ? " hide" : ""

    return (
      <nav className={`nav${hide}`} id="nav">
        <Link to={"/"} className="navbar-logo logo">
          <Image alt="SAN logo" />
        </Link>
        <div className="hamburger" onClick={() => this.hamburger()}>
          <div className="icon" />
        </div>
        <ul className="links">
          <li className="link">
            <Link to={"/"} className="link-text">
              Home
            </Link>
          </li>

          {links.map(link => (
            <li className="link" key={link}>
              <Link to={`/${link.toLowerCase()}`} className="link-text">
                {link}
              </Link>
            </li>
          ))}

          <li className="link">
            <a
              href="https://www.patreon.com/rysenetwork"
              rel="noopener"
              target="_blank"
            >
              <span className="primary">❤️</span> Support Us
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}
