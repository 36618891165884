import * as pic from "../images/profile"
import React from "react"
import {
  FaLinkedinIn,
  FaLink,
  FaTwitter,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa"

export default [
  {
    name: "Dani Kang",
    title: "Content",
    pronouns: "she/her",
    picture: pic.DaniKang,
    socials: [
      {
        link: "https://www.linkedin.com/in/dabin-kang-8bb603206/",
        icon: <FaLinkedinIn />,
      },
      { link: "https://www.instagram.com/ddabinni/", icon: <FaInstagram /> },
    ],
  },
  // {
  //   name: "Jasmine Martinez",
  //   title: "Content",
  //   pronouns: "she/her",
  //   picture: pic.JasmineMartinez,
  //   socials: [
  //     {
  //       link: "https://www.linkedin.com/in/jrbmartinez/",
  //       icon: <FaLinkedinIn />,
  //     },
  //     { link: "https://www.instagram.com/sampa_ita/", icon: <FaInstagram /> },
  //   ],
  // },
  {
    name: "Kyra Lee",
    title: "Content",
    pronouns: "she/her",
    picture: pic.KyraLee,
    socials: [
      {
        link: "https://www.linkedin.com/in/kyra-leenc/",
        icon: <FaLinkedinIn />,
      },
      { link: "https://www.instagram.com/kyxxnl/", icon: <FaInstagram /> },
    ],
  },
  {
    name: "Ramya K",
    title: "Content",
    pronouns: "she/her",
    picture: pic.RamyaK,
    socials: []
  },
  {
    name: "Sam Tran",
    title: "Copywriter",
    pronouns: "he/they",
    picture: pic.SamTran,
    socials: [
      {
        link: "https://www.linkedin.com/in/samnguyentran/",
        icon: <FaLinkedinIn />,
      },
    ],
  },
  // {
  //   name: "Niki Pham",
  //   title: "Design",
  //   pronouns: "she/her",
  //   picture: pic.NikiPham,
  //   socials: [
  //     { link: "https://www.instagram.com/nikipham_/", icon: <FaInstagram /> },
  //     { link: "https://twitter.com/nikipham", icon: <FaTwitter /> },
  //     { link: "https://www.facebook.com/niki.pham.568/", icon: <FaFacebook /> },
  //   ],
  // },
  {
    name: "Christina Wang",
    title: "Moderator",
    pronouns: "she/her",
    picture: pic.ChristinaWang,
    socials: [
      {
        link: "https://www.linkedin.com/in/christinawang3",
        icon: <FaLinkedinIn />,
      },
      { link: "https://www.twitter.com/Christina_Wang3", icon: <FaTwitter /> },
    ],
  },
  // {
  //   name: "Christian Avendano",
  //   title: "Podcast",
  //   pronouns: "he/him",
  //   picture: pic.ChristianAvendano,
  //   socials: [
  //     {
  //       link: "https://www.linkedin.com/in/christian-avendano",
  //       icon: <FaLinkedinIn />,
  //     },
  //     {
  //       link: "https://www.instagram.com/cavendano.92/",
  //       icon: <FaInstagram />,
  //     },
  //   ],
  // },

  {
    name: "Yibiao Wang",
    title: "Podcast",
    pronouns: "he/him",
    picture: pic.YibiaoWang,
    socials: [
      {
        link: "https://www.linkedin.com/in/yibiaowang/",
        icon: <FaLinkedinIn />,
      },
      { link: "https://www.instagram.com/yibiao.wang", icon: <FaInstagram /> },
    ],
  },

  // {
  //   name: "Miriam Yang",
  //   title: "Research",
  //   pronouns: "she/her",
  //   picture: pic.MiriamYang,
  //   socials: [
  //     {
  //       link: "https://www.linkedin.com/in/miriam-yang/",
  //       icon: <FaLinkedinIn />,
  //     },
  //   ],
  // },
  {
    name: "Gavin Ching",
    title: "Website",
    pronouns: "he/him",
    picture: pic.GavinChing,
    socials: [
      {
        link: "https://www.linkedin.com/in/gavinching/",
        icon: <FaLinkedinIn />,
      },
    ],
  },

  {
    name: "Lily Li",
    title: "Website",
    pronouns: "she/her",
    picture: pic.LilyLi,
    socials: [
      {
        link: "https://www.linkedin.com/in/lily-dev/",
        icon: <FaLinkedinIn />,
      },
    ],
  },

  {
    name: "Griffin",
    title: "Cat",
    pronouns: "he/him",
    picture: pic.griffin,
    socials: [{ link: "https://griffin-cat.netlify.app/", icon: <FaLink /> }],
  },
]
