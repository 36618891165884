import React from "react"
import { Link } from "gatsby"
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPatreon,
} from "react-icons/fa"
import { orgName } from "../constants"

import logo from "../images/logo-light.png"

export default function Footer({ siteTitle }) {
  return (
    <div className="footer-container">
      <div
        className="ml-form-embed"
        data-account="2893687:i7i7d1b0i7"
        data-form="3561274:a4g4u5"
      />

      <footer className="footer">
        <div className="left-container">
          <Link to={"/"}>
            <img src={logo} className="logo" alt="light SAN logo in footer" />
            <div className="logo-name">{orgName}</div>
          </Link>
          <div className="social-container">
            <a
              href="https://www.facebook.com/ryseasiannetwork"
              rel="noopener"
              target="_blank"
            >
              <FaFacebookF className="icon" />
            </a>
            <a
              href="https://www.instagram.com/ryse.network/"
              rel="noopener"
              target="_blank"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/ryse-network"
              rel="noopener"
              target="_blank"
            >
              <FaLinkedinIn className="icon" />
            </a>
          </div>
          <a
            href="https://www.patreon.com/rysenetwork"
            className="support-button"
            rel="noopener"
            target="_blank"
          >
            ❤️ Support Us
          </a>
        </div>
        <div className="right-container">
          <div className="right-content">
            <div className="right-title">Company</div>
            <div className="right-links">
              <Link className="link" to={"/about"}>
                <div className="link-text">About</div>
              </Link>
              <Link className="link" to={"/about"}>
                <div className="link-text">Values</div>
              </Link>
              <Link className="link" to={"/partners"}>
                <div className="link-text">Partners</div>
              </Link>
              <Link className="link" to={"/events"}>
                <div className="link-text">Events</div>
              </Link>
            </div>
          </div>
          <div className="right-content">
            <div className="right-title">Stories</div>
            <div className="right-links">
              <Link className="link" to={"/stories"}>
                <div className="link-text">All</div>
              </Link>
              <Link className="link" to={"/stories#blogs"}>
                <div className="link-text">Blogs</div>
              </Link>
              <Link className="link" to={"/stories"}>
                <div className="link-text">Podcasts</div>
              </Link>
            </div>
          </div>
          <div className="right-content">
            <div className="right-title">Contact</div>
            <div className="right-links">
              <Link className="link" to={"/contact"}>
                <div className="link-text">Support</div>
              </Link>
              <Link className="link" to={"/partners"}>
                <div className="link-text">Partners</div>
              </Link>
            </div>
          </div>
          <div className="right-content">
            <div className="right-title">Legal</div>
            <div className="right-links">
              {/*<Link className="link">*/}
              {/*  <div className="link-text">Terms of Service</div>*/}
              {/*</Link>*/}
              <Link className="link" to={"/privacy-policy"}>
                <div className="link-text">Privacy Policy</div>
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <span className="treasure">
          Maybe the real network is the friends we made along the way{" "}
          <span role="img" aria-label="rainbow">
            🌈
          </span>
        </span>
        <span>
          © {new Date().getFullYear()} {siteTitle}
        </span>
      </div>
    </div>
  )
}
