// newsletter
const nextPopup =
  typeof window !== "undefined" ? localStorage.getItem("nextNewsletter") : null

export const getNewsletter = () => {
  const popup = document.getElementById("popup")
  if (popup.className === "popup") popup.className += "-show"
  else popup.className = "popup"

  if (parseInt(nextPopup) > new Date().getTime() || nextPopup === "submitted")
    return

  let expires = new Date()
  expires = expires.setDate(expires.getDate() + 7)

  localStorage.setItem("nextNewsletter", expires)
}

export const submitEmail = () => {
  localStorage.setItem("nextNewsletter", "submitted")
}

export const showPopup = () => {
  if (parseInt(nextPopup) > new Date().getTime() || nextPopup === "submitted")
    return false
  else return true
}

// helper functions
export const throttle = (func, limit) => {
  let waiting
  return function () {
    if (!waiting) {
      func.apply(this, arguments)
      waiting = true

      setTimeout(() => {
        waiting = false
      }, limit)
    }
  }
}
