/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../scss/index.scss"

import Navbar from "./Navbar"
import Footer from "./Footer"

import { getNewsletter } from "../utils"
import CookieConsent from "react-cookie-consent"
import Link from "gatsby-link"

const Layout = ({ children }) => {
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    const showPopup = () => {
      const nextPopup = localStorage.getItem("nextNewsletter")
      if (
        parseInt(nextPopup) > new Date().getTime() ||
        nextPopup === "submitted"
      )
        return false
      else return true
    }

    setPopup(showPopup())
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const closePopup = () => {
    setPopup(false)
    getNewsletter()
  }

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Navbar />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1040,
          padding: `0 1.0875rem 1.45rem`,
          minHeight: `50vh`,
        }}
      >
        <main>{children}</main>
        <Helmet>
          <script>{`
(function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
_.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

var ml_account = ml('accounts', '2893687', 'i7i7d1b0i7', 'load');
`}</script>
        </Helmet>
      </div>
      <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        buttonStyle={{
          background: "#09ab7f",
          color: "#fff",
          borderRadius: "10px",
        }}
        declineButtonStyle={{
          borderRadius: "10px",
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        Ryse Network uses cookies for functional and analytical purposes. Please
        read our{" "}
        <Link
          className="underline"
          style={{ color: "#09ab7f" }}
          to="/privacy-policy"
        >
          Privacy Policy
        </Link>{" "}
        for more information.
      </CookieConsent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
